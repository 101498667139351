import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import LockIcon from '@material-ui/icons/LockOutlined'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import withRoot from '../withRoot'
import Helper from '../utils/Helper'
import Config from '../config'
import { LinearProgress } from '@material-ui/core'
import { Redirect } from 'react-router'
import ErrorIcon from '@material-ui/icons/Error'
import { withLoginContext } from '../contexts/login-context'

const styles: any = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
})

const SignIn = (props: any) => {
  const [email, setEmail] = useState<string | null>(null)
  const [password, setPassword] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    Helper.isAuthenticated(),
  )
  const [error, setError] = useState<string | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (name === 'email') {
      setEmail(value)
    } else if (name === 'password') {
      setPassword(value)
    }
  }

  const doLogin = (event) => {
    event.preventDefault()
    // start loading mask
    setIsLoading(true)
    setError(null)

    const { source } = Config
    const data = { username: email, password, source }

    Helper.ApiRequest(
      '/auth/login',
      {
        method: 'POST',
        body: JSON.stringify(data),
      },
      false,
    )
      .then(async (response: any) => {
        if (response.error) {
          setError(response.message)
          setIsLoading(false)
          return await Promise.reject(response.message)
        }
        await Helper.setEncryptionKey(response.token).then(() => {
          Helper.setLocalStorageItem(
            Config.localStorage.tokenIndex,
            response.token,
          )
        })
      })
      .then(Helper.whoami)
      .then((prof: any) => {
        const { role } = prof
        if (!Config.allowedRoles.includes(role)) {
          Helper.logOut()
          setError('ليس لديك صلاحية')
          setIsLoading(false)
          return
        }
        // check if allowed
        const isAuthenticated = Helper.isAuthenticated()
        setIsLoading(false)
        setIsAuthenticated(isAuthenticated)
        props.setAuth(isAuthenticated)
      })
      // .then(() => { @TODO: double check this
      //   debugger;
      //   Helper.accessLog(null, null, "login", true)
      // })
      .catch((err) => {
        console.log('error login', err)
      })
  }

  const getFromPath = () => {
    const { state } = props.location
    if (!state || !state.from) {
      return '/'
    }
    return state.from.pathname
  }

  const { classes } = props
  if (isAuthenticated) return <Redirect to={getFromPath()} />

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          تسجيل الدخول
        </Typography>
        <form className={classes.form} onSubmit={doLogin}>
          {isLoading && <LinearProgress />}

          {error && (
            <Typography
              color="error"
              variant="caption"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <ErrorIcon fontSize="small" />
              {error}
            </Typography>
          )}

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">البريد الالكتروني</InputLabel>
            <Input
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              disabled={isLoading}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">كلمة المرور</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              disabled={isLoading}
            />
          </FormControl>
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            دخول
          </Button>
        </form>
      </Paper>
    </main>
  )
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRoot(withStyles(styles)(withLoginContext(SignIn) as any))
