import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import MapForm from '../../components/MapForm'
import { withEpisodes } from '../../contexts/data-context'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'

const styles = (theme) => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
})

const MapDetail = (props: any) => {
  const [state, setState] = useState<any>(null)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    const { id } = props.match.params
    const map = await Helper.ApiRequest(`/maps/${id}`)
    setState({ map })
  }

  const save = () => {
    const { map }: any = state
    const { enqueueSnackbar } = props
    const [promise, onSuccess] = getSaveRequest(map)
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Map succesfully saved',
      fail: 'Failed to save map',
      onSuccess,
    })
  }

  const getSaveRequest = (map) => {
    const { history } = props
    const { _id } = map
    const path = `/maps/${_id}`
    const method = 'PUT'
    const body = JSON.stringify(map)
    const promise = Helper.ApiRequest(path, { method, body })

    const onSuccess = () => {
      history.push(`/maps/${_id}`)
      fetchData()
    }
    return [promise, onSuccess]
  }

  const handleChange = (map) => {
    setState({ map })
  }

  const { classes } = props
  const { map }: any = state
  if (!map) return null

  return (
    <React.Fragment>
      <FormTitle map={map} />
      <Paper className={classes.paper}>
        {/* @ts-expect-error legacy */}
        <MapForm map={map} onChange={handleChange} onSave={save} />
      </Paper>
    </React.Fragment>
  )
}
MapDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(
  // @ts-expect-error legacy
  withEpisodes(withRouter(withStyles(styles)(MapDetail))),
)

function FormTitle({ map }) {
  const { item = {} } = map || {}
  const { title } = item
  return (
    <Typography component="h2" variant="h4" align="center">
      Map : {title}
    </Typography>
  )
}
