import React from 'react'
import { SnackbarProvider } from 'notistack'
import Routing from './routing'
import { withStyles } from '@material-ui/core'
import {
  SeriesProvider,
  PathsProvider,
  EpisodesProvider,
  ChaptersProvider,
} from './contexts/data-context'
import { LoginProvider } from './contexts/login-context'
import withRoot from './withRoot'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles: any = (theme) => ({
  variantSuccess: {
    direction: 'rtl',
  },
  variantError: {
    direction: 'rtl',
  },
  variantInfo: {
    direction: 'rtl',
  },
  variantWarning: {
    direction: 'rtl',
  },
})

const App = ({ classes }) => (
  <LoginProvider>
    <PathsProvider>
      <SeriesProvider>
        <EpisodesProvider>
          <ChaptersProvider>
            <SnackbarProvider
              maxSnack={2}
              autoHideDuration={2000}
              classes={classes}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Routing />
            </SnackbarProvider>
          </ChaptersProvider>
        </EpisodesProvider>
      </SeriesProvider>
    </PathsProvider>
  </LoginProvider>
)

export default withRoot(withStyles(styles, { withTheme: true })(App))
