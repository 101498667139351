import React, { useState } from 'react'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import LogoutIcon from '@material-ui/icons/SettingsPower'
import QuickAccessIcon from '@material-ui/icons/PlaylistAdd'
import RefreshIcon from '@material-ui/icons/Refresh'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../withRoot'
import { drawerWidth } from './constants'
import PropTypes from 'prop-types'
import Helper from '../utils/Helper'
import { Redirect } from 'react-router'
import { withDrawerContext } from '../contexts/drawer-context'
import ActionMenu from './actions-menu'
import { withLoginContext } from '../contexts/login-context'
import { withSnackbar } from 'notistack'
import Tooltip from '@material-ui/core/Tooltip'

const TITLE = 'ZidoWorld content'
const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
})

const Header = (props: any) => {
  const [state, setState] = useState<any>({
    logedOut: false,
    isLoading: false,
    anchorEl: null,
    openMenu: false,
  })

  const toggleActionsMenu = (event) => {
    const { anchorEl } = state
    if (anchorEl) setState({ anchorEl: null, openMenu: false })
    else setState({ anchorEl: event.currentTarget, openMenu: true })
  }

  const toggleReferesh = (enqueueSnackbar) => async () => {
    await Helper.ApiRequest('/cron/competitions/refresh-competition')
    setTimeout(() => {
      enqueueSnackbar('Success sync competition', {
        variant: 'success',
      })
    }, 2000)
  }

  const logOut = () => {
    Helper.logOut()
    setState({ logedOut: true })
    props.setAuth(false)
  }

  const { openMenu, anchorEl } = state
  const { classes, onOpenDrawer, drawerContext, enqueueSnackbar } = props
  const { open } = drawerContext
  if (state.logedOut) return <Redirect to={'/login'} />
  return (
    <AppBar
      position="absolute"
      className={classNames(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar disableGutters={!open} className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={onOpenDrawer}
          className={classNames(
            classes.menuButton,
            open && classes.menuButtonHidden,
          )}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {TITLE}
        </Typography>
        <Tooltip title="Sync competition">
          <IconButton
            color="inherit"
            onClick={toggleReferesh(enqueueSnackbar)}
            about="Sync competition"
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <IconButton color="inherit" onClick={toggleActionsMenu}>
          <QuickAccessIcon />
          <ActionMenu open={openMenu} anchorEl={anchorEl} />
        </IconButton>
        <IconButton color="inherit" onClick={logOut}>
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
Header.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
}

export default withLoginContext(
  withSnackbar(
    // @ts-expect-error legacy
    withDrawerContext(
      withRoot(withStyles(styles, { withTheme: true })(Header)),
    ),
  ),
)
