import React, { useState } from 'react'
import Helper from '../utils/Helper'
import { ConfirmationDialog } from './Dialogs'
import { withSnackbar } from 'notistack'
import { doAndSnack } from '../utils/snackbar'

const DeleteData: any = withSnackbar((props: any) => {
  const [open, setOpen] = useState(false)

  const askDelete = () => {
    setOpen(true)
  }

  const handleConfirmDelete = async () => {
    const { enqueueSnackbar, path, success, fail, onAfterDelete } = props
    const promise = Helper.ApiRequest(path, {
      method: 'DELETE',
    })
    doAndSnack(promise, enqueueSnackbar, {
      success,
      fail,
      onSuccess: onAfterDelete,
    })
  }

  const handleClose = () => {
    const { onClose } = props
    if (open) setOpen(false)
    if (onClose) onClose()
  }

  const { text, component: Component, forceOpen } = props
  return (
    <React.Fragment>
      <ConfirmationDialog
        open={open || forceOpen}
        text={text}
        onClose={handleClose}
        onCancel={handleClose}
        onOk={handleConfirmDelete}
      />
      {Component && <Component onClick={askDelete} />}
    </React.Fragment>
  )
})

export default DeleteData

export function DeleteChapter({ chapter, ...others }) {
  const { _id, episode, title } = chapter || {}
  const path = `/chapters/${_id}`
  const text = episode
    ? `"${title}" belongs to an episode, delete anyway ? `
    : `"${title}" doesn't belong to an episode, delete it ? `
  return (
    <DeleteData
      path={path}
      text={text}
      success="Chapter succesfully deleted"
      fail="Failed to delete chapter"
      {...others}
    />
  )
}
export function DeleteEpisode({ episode, ...others }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _id, title, nbChapters, nbActivities } = episode || {}
  const path = `/episodes/${_id}`
  const text =
    nbChapters || nbActivities
      ? `The episode has activities, chapters and games, are you sure to delete this episode?`
      : `Are you sure to delete this episode?`
  return (
    <DeleteData
      path={path}
      text={text}
      success="Episode succesfully deleted"
      fail="Failed to delete episode"
      {...others}
    />
  )
}
export function DeleteSeries({ series, ...others }) {
  const { _id, title, episodesCount } = series
  const path = `/series/${_id}`
  const text = episodesCount
    ? `${title} has ${episodesCount} episodes, delete anyway ?`
    : `${title} has no episodes, delete it ?`
  return (
    <DeleteData
      path={path}
      text={text}
      success="Episode succesfully deleted"
      fail="Failed to delete episode"
      {...others}
    />
  )
}
export function DeleteGame({ game, ...others }) {
  const { _id, title } = game || {}
  const path = `/activities/${_id}`
  const text = `Delete game ${title} ?`
  return (
    <DeleteData
      path={path}
      text={text}
      success="Game succesfully deleted"
      fail="Failed to delete game"
      {...others}
    />
  )
}

export const DeleteBook = ({ book, ...others }) => {
  const { _id, title } = book || {}
  const path = `/activities/${_id}`
  const text = `Delete book ${title} ?`
  return (
    <DeleteData
      path={path}
      text={text}
      success="Book succesfully deleted"
      fail="Failed to delete book"
      {...others}
    />
  )
}

export const DeleteCompetition = ({ competition, ...others }) => {
  const { _id } = competition || {}
  const path = `/competitionsNew/${_id}`

  const DELETE_MESSAGE = 'هل أنت متأكد من حذف المساق وكل السباقات التي بداخله؟'
  const text = DELETE_MESSAGE
  return (
    <DeleteData
      path={path}
      text={text}
      success="Competition succesfully deleted"
      fail="Failed to delete competition"
      {...others}
    />
  )
}

export const DeleteChallenge = ({ challenge, ...others }) => {
  const { _id } = challenge || {}
  const path = `/challenges/${_id}`

  const DELETE_MESSAGE = 'هل أنت متأكد من حذف السباق ؟'
  const text = DELETE_MESSAGE
  return (
    <DeleteData
      path={path}
      text={text}
      success="Challenge succesfully deleted"
      fail="Failed to delete challenge"
      {...others}
    />
  )
}
