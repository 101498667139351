import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab'
import { CircularProgress, Paper, Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Helper from '../../utils/Helper'
import { withDrawerContext } from '../../contexts/drawer-context'

import { withSnackbar } from 'notistack'
import { doAndSnack, snackProgressBar } from '../../utils/snackbar'

import EpisodeTable from '../../components/EpisodeTable'
import SeriesForm from '../../components/SeriesForm'

const styles: any = (theme) => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
  fab: {
    top: 'auto',
    right: '3rem',
    bottom: '2rem',
    left: 'auto',
    position: 'fixed',
  },
})

const SeriesDetail = (props: any) => {
  const { classes, history, location, enqueueSnackbar }: any = props

  // const { slug } = props.match.params

  const [tabValue, setTabValue] = useState(0)
  const [episodes, setEpisodes] = useState([])
  const [title, setTitle] = useState('Title')
  const [series, setSeries] = useState(location.state)
  const [loading, setLoading] = useState(false)

  const fetchEpisodes = async () => {
    try {
      setLoading(true)
      const result = await Helper.ApiRequest('/episodes')
      const filtered = result.filter(
        (data) => data.series && data.series._id === series.id,
      )
      setEpisodes(filtered)
      setLoading(false)
    } catch (error) {
      console.log(error)
      snackProgressBar(
        enqueueSnackbar,
        {
          success: '',
          fail: 'Failed to fetch data. Please refresh the page',
        },
        false,
      )
      setLoading(false)
    }
  }

  const fetchOneSeries = async () => {
    try {
      setLoading(true)
      const result = await Helper.ApiRequest(`/series/${series?._id}`)
      setSeries({ ...result })
    } catch (error) {
      console.log(error)
      snackProgressBar(
        enqueueSnackbar,
        {
          success: '',
          fail: 'Failed to fetch data. Please refresh the page',
        },
        false,
      )
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchEpisodes()
    fetchOneSeries()
    setTitle(series?.title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeTab = (e, value) => {
    setTabValue(value)
  }

  const saveSeries = (refresh: boolean) => {
    try {
      if (series && !series.title) {
        snackProgressBar(
          enqueueSnackbar,
          {
            success: '',
            fail: 'Title is required',
          },
          false,
        )
        return
      }

      if (series && !series.slug) {
        snackProgressBar(
          enqueueSnackbar,
          {
            success: '',
            fail: 'Slug is required',
          },
          false,
        )
        return
      }

      if (!series?.icon) {
        enqueueSnackbar('Icon is required', {
          variant: 'error',
        })
        return
      }

      const promise = Helper.ApiRequest(`/series/${series.id}`, {
        method: 'PUT',
        body: JSON.stringify(series),
      })

      const onSuccess = () => {
        if (refresh) {
          window.location.reload()
        } else {
          history.push('/series')
        }
      }

      doAndSnack(promise, enqueueSnackbar, {
        success: 'Series succesfully saved',
        fail: 'Failed to save series',
        onSuccess,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (series) => {
    setSeries({
      ...series,
    })
  }

  const addEpisode = () => {
    history.push(`/episodes/add?series=${series.slug}`)
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        {title}
      </Typography>
      {!loading ? (
        <Paper className={classes.paper}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            //   action={this.getAction}
          >
            <Tab label="Details" />
            <Tab label="Episodes" />
          </Tabs>

          {tabValue === 0 && (
            <TabContainer>
              <SeriesForm
                // @ts-expect-error legacy
                series={series}
                onChange={handleChange}
                onSave={saveSeries}
              />
            </TabContainer>
          )}
          {tabValue === 1 && (
            <EpisodeTab episodes={episodes} onAdd={addEpisode} />
          )}
        </Paper>
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </React.Fragment>
  )
}

export default withSnackbar(
  // @ts-expect-error legacy
  withDrawerContext(withRouter(withTheme()(withStyles(styles)(SeriesDetail)))),
)

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

const EpisodeTab = withStyles(styles)(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ episodes, classes, e, onAdd }: any) => {
    return (
      <TabContainer>
        <EpisodeTable data={episodes} />
        <Fab
          color="secondary"
          aria-label="Add"
          className={classes.fab}
          onClick={onAdd}
        >
          <AddIcon />
        </Fab>
      </TabContainer>
    )
  },
)
