import React, { useState, useCallback, useMemo } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  LinearProgress,
  withStyles,
} from '@material-ui/core'
import CloudUpload from '@material-ui/icons/CloudUpload'
import DoneIcon from '@material-ui/icons/Done'
import WarningIcon from '@material-ui/icons/Warning'
import { withSnackbar } from 'notistack'
import Helper from '../utils/Helper'
import { getExtension, toBlob, readableSize } from '../utils/image'
import { assetServer, getPath, isInServer } from '../utils/url'
import { LtrTextField } from './Forms'

const styles = (theme) => ({
  card: {
    maxWidth: 345,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  cardContent: {
    padding: theme.spacing.unit,
  },
  cardActions: {
    padding: 0,
  },
  cardInput: {
    display: 'none',
  },
  cardField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  chip: {
    margin: theme.spacing.unit,
  },
})

const CardControl: React.FC<any> = ({
  classes,
  url = '',
  title,
  uploadDefaultPrefix: prefix,
  uploadDefaultName: name,
  maxFileSize,
  enqueueSnackbar: snack,
  onChangeUrl,
  onUpload,
}) => {
  const [state, setState] = useState({
    imageExist: false,
    loading: false,
    src: null as string | null,
    open: false,
    upload: null as File | null,
    uploadName: '',
    hash: Date.now(),
    width: 0,
    height: 0,
    size: 0,
  })

  const inServer = useCallback((url: string) => isInServer(url), [])
  const getPathMemo = useCallback((url: string) => getPath(url), [])
  const getStampedUrl = useCallback((url: string, hash: number) => {
    const mediaUrl = Helper.MediaURL(url)
    return mediaUrl?.indexOf('?') > 0
      ? `${mediaUrl}&${hash}`
      : `${mediaUrl}?${hash}`
  }, [])

  const setImageExist = useCallback((url: string) => {
    fetch(url).then((res) => {
      setState((prevState) => ({ ...prevState, imageExist: res.ok }))
    })
  }, [])

  const handleLoadImage = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const {
        src,
        naturalWidth: width,
        naturalHeight: height,
      } = e.currentTarget
      setState((prevState) => ({ ...prevState, src, width, height }))

      toBlob(src).then((blob) => {
        setState((prevState) => ({ ...prevState, size: blob.size }))
        if (!inServer(url)) {
          const extension = getExtension(blob)
          const uploadName = `${prefix}/${name}.${extension}`
          const upload = new File([blob], uploadName, { type: blob.type })
          setState((prevState) => ({ ...prevState, upload, uploadName }))
          setImageExist(assetServer + uploadName)
        }
      })
    },
    [url, prefix, name, inServer, setImageExist],
  )

  const handleUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const upload = e.target.files?.[0]
      if (!upload) return

      if (maxFileSize && upload.size > maxFileSize) {
        snack(`File size bigger than ${maxFileSize / 1024 / 1024} MB`, {
          variant: 'error',
        })
      } else {
        const uploadName = prefix + '/' + upload.name
        setState((prevState) => ({ ...prevState, upload, uploadName }))
        setImageExist(assetServer + uploadName)
      }
    },
    [maxFileSize, prefix, snack, setImageExist],
  )

  const handleUploadChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const uploadName = e.target.value
      setState((prevState) => ({ ...prevState, uploadName }))
      setImageExist(assetServer + uploadName)
    },
    [setImageExist],
  )

  const handleChangeUrl = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const fullValue = inServer(url) ? assetServer + value : value

      if (!value) {
        onChangeUrl('')
      } else {
        onChangeUrl(fullValue)
      }
    },
    [url, inServer, onChangeUrl],
  )

  const putInServer = useCallback(async () => {
    if (state.loading) return
    setState((prevState) => ({ ...prevState, loading: true }))

    const formData = new FormData()
    if (state.upload) formData.append('upload', state.upload)
    Helper.uploadAsset(state.uploadName, formData)
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          upload: null,
          uploadName: '',
          hash: Date.now(),
        }))
        onChangeUrl(assetServer + state.uploadName)
        snack('Upload successful', { variant: 'success' })
        if (onUpload) onUpload()
      })
      .catch((err) => {
        console.error(err)
        setState((prevState) => ({
          ...prevState,
          loading: false,
          upload: null,
          uploadName: '',
        }))
        snack('Error upload', { variant: 'error' })
      })
  }, [
    state.loading,
    state.upload,
    state.uploadName,
    onChangeUrl,
    snack,
    onUpload,
  ])

  const path = useMemo(() => getPathMemo(url), [getPathMemo, url])
  const src = useMemo(
    () => getStampedUrl(url, state.hash),
    [getStampedUrl, url, state.hash],
  )

  return (
    <Card className={classes.card}>
      <CardMedia
        component="img"
        className={classes.media}
        image={src}
        title={title}
        onLoad={handleLoadImage}
      />

      <CardContent className={classes.cardContent}>
        {state.loading && <LinearProgress />}
        {path && (
          <Chips
            classes={classes}
            width={state.width}
            height={state.height}
            size={state.size}
          />
        )}
        <PathField
          classes={classes}
          inServer={inServer(url)}
          path={path}
          onChange={handleChangeUrl}
          label={title}
        />
        <UploadField
          classes={classes}
          upload={state.upload}
          imageExist={state.imageExist}
          uploadName={state.uploadName}
          onChange={handleUploadChange}
        />
      </CardContent>
      <CardActions className={classes.cardActions}>
        {state.upload ? (
          <SimpleButton onClick={putInServer} text="Upload to server" />
        ) : (
          <UploadButton
            classes={classes}
            onUpload={handleUpload}
            prefix={prefix}
          />
        )}
      </CardActions>
    </Card>
  )
}

const Chips: React.FC<any> = ({ classes, width, height, size }) => {
  const widthLabel = `${width}x${height}`
  const sizeLabel = readableSize(size)
  return (
    <>
      <Chip className={classes.chip} label={widthLabel} />
      <Chip className={classes.chip} label={sizeLabel} />
    </>
  )
}

const SimpleButton: React.FC<any> = ({ text, ...props }) => {
  return (
    <Button size="small" color="primary" {...props}>
      {text}
    </Button>
  )
}

const UploadButton: React.FC<any> = ({ classes, onUpload, prefix }) => {
  const id = `contained-button-file-${prefix}`

  return (
    <>
      <input
        accept="image/*"
        className={classes.cardInput}
        id={id}
        type="file"
        onChange={onUpload}
      />
      <label htmlFor={id}>
        <Button
          size="small"
          color="primary"
          component="span"
          className={classes.button}
        >
          Upload
        </Button>
      </label>
    </>
  )
}

const PathField: React.FC<any> = ({ classes, path, inServer, ...others }) => {
  if (!path) return null
  const fieldProps = {
    helperText: inServer ? assetServer : '',
    value: path,
    className: classes.cardField,
  }
  const chipServerProps: any = {
    label: inServer ? 'In server' : 'Not in server',
    color: inServer ? 'secondary' : 'default',
    icon: inServer ? <DoneIcon /> : <CloudUpload />,
    className: classes.chip,
  }
  return (
    <>
      <Chip {...chipServerProps} />
      <LtrTextField {...fieldProps} {...others} />
    </>
  )
}

const UploadField: React.FC<any> = ({
  classes,
  upload,
  uploadName,
  imageExist,
  onChange,
}) => {
  if (!upload) return null
  const fieldProps = {
    value: uploadName,
    className: classes.cardField,
    label: 'New file path',
    onChange,
  }
  const chipExistProps: any = {
    label: imageExist ? 'Exists' : "Don't exist",
    color: imageExist ? 'default' : 'secondary',
    icon: imageExist ? <WarningIcon /> : <DoneIcon />,
    className: classes.chip,
  }

  const fileURL = URL.createObjectURL(upload)

  return (
    <>
      <img src={fileURL} height={200} width={200} alt="Upload preview" />
      <LtrTextField {...fieldProps} />
      <Chip {...chipExistProps} />
    </>
  )
}

export default withStyles(styles)(withSnackbar(CardControl))
