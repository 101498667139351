import React, { useState } from 'react'
import Helper from '../utils/Helper'

export const LoginContext = React.createContext({
  isAuth: false,
  setAuth: () => {},
})

export const LoginProvider = (props: any) => {
  const setAuth = (isAuth) => {
    setState({ isAuth })
  }

  const [state, setState] = useState<any>({
    isAuth: Helper.isAuthenticated(),
    setAuth,
  })

  const { isAuth } = state
  const { children } = props
  const context = { isAuth, setAuth }
  return (
    // @ts-expect-error legacy
    <LoginContext.Provider value={context}>{children}</LoginContext.Provider>
  )
}
export const withLoginContext = (Element) =>
  React.forwardRef((props, ref) => (
    <LoginContext.Consumer>
      {(context) => (
        <Element
          isAuth={context.isAuth}
          setAuth={context.setAuth}
          {...props}
          ref={ref}
        />
      )}
    </LoginContext.Consumer>
  ))
