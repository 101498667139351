import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import MaterialTable from 'material-table'
import Checkbox from '@material-ui/core/Checkbox'
import TablePagination from '@material-ui/core/TablePagination'
import Helper from '../../utils/Helper'

const pageTitle = 'Maps'
const columns: any = [
  { title: 'Ref', sort: false, render: (r) => (r.item ? r.item.title : '') },
  { title: 'Type', field: 'kind' },
  {
    title: 'Published',
    field: 'state',
    type: 'boolean',
    render: (rowData) => (
      <Checkbox checked={rowData.state === 'published'} disabled />
    ),
  },
]
const options = {
  columnsButton: false,
  exportButton: false,
  filtering: true,
  emptyRowsWhenPaging: false,
  pageSize: 10,
  actionsColumnIndex: -1,
}

// The pagination component is not right with rtl
const components = {
  Pagination: (props) => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

const Maps = (props: any) => {
  const [maps, setMaps] = useState<any>([])

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit map',
      onClick: (e, row) => {
        handleEdit(e, row)
      },
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Helper.ApiRequest('/maps')
        setMaps(data)
      } catch (error) {
        console.error('Error fetching maps:', error)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEdit = (e, rowData) => {
    props.history.push(`/maps/${rowData._id}`)
  }

  return (
    <MaterialTable
      columns={columns}
      data={maps}
      title={pageTitle}
      options={options}
      components={components}
      actions={actions}
    />
  )
}

export default withRouter(Maps)
