import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import TablePagination from '@material-ui/core/TablePagination'
import MaterialTable from 'material-table'
import { withRouter } from 'react-router-dom'
import { DeleteChapter } from './Delete'

const pageTitle = 'Chapters'
const columns = [
  { title: 'Title', field: 'title' },
  { title: 'Slug', field: 'slug' },
  { title: 'Free', field: 'isFree', type: 'boolean' },
  {
    title: 'Order',
    sorting: false,
    render: (rowData) => rowData.tableData.id + 1,
  },
  {
    title: 'Published',
    field: 'state',
    type: 'boolean',
    render: (rowData) => (
      <Checkbox checked={rowData.state === 'published'} disabled />
    ),
  },
]
const options = {
  sorting: true,
  columnsButton: true,
  exportButton: true,
  // filtering: true,
  emptyRowsWhenPaging: false,
  pageSize: 10,
  actionsColumnIndex: -1,
}

// The pagination component is not right with rtl
const components = {
  Pagination: (props) => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

const ChapterTable = (props) => {
  const [deleteChapter, setDeleteChapter] = useState(null)

  const actions = [
    (rowData) => ({
      icon: 'arrow_upward',
      tooltip: 'Move up',
      disabled: rowData.tableData.id === 0,
      onClick: (e, row) => {
        handleUp(e, row)
      },
    }),
    (rowData) => ({
      icon: 'arrow_downward',
      tooltip: 'Move down',
      disabled: rowData.tableData.id === props.data.length - 1,
      onClick: (e, row) => {
        handleDown(e, row)
      },
    }),
    {
      icon: 'edit',
      tooltip: 'Edit chapter',
      onClick: (e, row) => {
        handleEdit(e, row)
      },
    },
    {
      icon: 'delete',
      tooltip: 'Delete chapter',
      onClick: (e, row) => {
        handleDelete(e, row)
      },
    },
  ]

  const handleEdit = (e, rowData) => {
    const { history } = props
    history.push(`/chapters/${rowData.slug}`)
  }

  const handleDelete = (e, rowData) => {
    openDelete(rowData)
  }

  const handleAfterDelete = () => {
    const { onDelete } = props
    closeDelete()
    if (onDelete) onDelete()
  }

  const handleUp = (e, rowData) => {
    const { onUp } = props
    if (onUp) onUp(rowData)
  }

  const handleDown = (e, rowData) => {
    const { onDown } = props
    if (onDown) onDown(rowData)
  }

  const openDelete = (rowData) => {
    setDeleteChapter(rowData)
  }

  const closeDelete = () => {
    setDeleteChapter(null)
  }

  const { data } = props
  return (
    <React.Fragment>
      <MaterialTable
        // @ts-expect-error legacy
        columns={columns}
        data={data}
        title={pageTitle}
        options={options}
        components={components}
        actions={actions}
      />
      <DeleteChapter
        chapter={deleteChapter}
        forceOpen={!!deleteChapter}
        onClose={closeDelete}
        onAfterDelete={handleAfterDelete}
      />
    </React.Fragment>
  )
}

export default withRouter(ChapterTable)
