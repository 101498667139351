import React, { useEffect, useState } from 'react'
import Helper from '../utils/Helper'
import { withLoginContext } from './login-context'

// Generic data provider
export const dataProvider = (Context, path) =>
  withLoginContext((props: any) => {
    const { isAuth } = props
    const [data, setData] = useState<any>(null) // Replace `any` with the appropriate type if possible

    useEffect(() => {
      const fetchData = async () => {
        if (!isAuth) return
        try {
          const result = await Helper.ApiRequest(path)
          setData(result)
        } catch (error) {
          console.error('Failed to fetch data:', error)
        }
      }

      fetchData()
    }, [isAuth])

    const { children } = props
    return <Context.Provider value={data}>{children}</Context.Provider>
  })

export const PathsContext: any = React.createContext({ data: [] })
export const SeriesContext: any = React.createContext({ data: [] })
export const EpisodesContext: any = React.createContext({ data: [] })
export const ChaptersContext: any = React.createContext({ data: [] })
export const PathsProvider: any = dataProvider(PathsContext, '/paths')
export const SeriesProvider: any = dataProvider(SeriesContext, '/series')
export const EpisodesProvider: any = dataProvider(EpisodesContext, '/episodes')
export const ChaptersProvider: any = dataProvider(ChaptersContext, '/chapters')

// These HOC are helpers to inject data in props
export const withPaths = (Element) =>
  React.forwardRef((props, ref) => (
    <PathsContext.Consumer>
      {(context) => <Element paths={context} {...props} ref={ref} />}
    </PathsContext.Consumer>
  ))
export const withEpisodes = (Element) =>
  React.forwardRef((props, ref) => (
    <EpisodesContext.Consumer>
      {(context) => <Element episodes={context} {...props} ref={ref} />}
    </EpisodesContext.Consumer>
  ))

export const withChapters = (Element) =>
  React.forwardRef((props, ref) => (
    <ChaptersContext.Consumer>
      {(context) => <Element chapters={context} {...props} ref={ref} />}
    </ChaptersContext.Consumer>
  ))

export const withSeries = (Element) =>
  React.forwardRef((props, ref) => (
    <SeriesContext.Consumer>
      {(context) => <Element seriesContext={context} {...props} ref={ref} />}
    </SeriesContext.Consumer>
  ))
