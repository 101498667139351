import React from 'react'
import Config from '../config'
import { Typography } from '@material-ui/core'
const api = Config.apiURL
const GameFrame = (props) => {
  const handleLoad = (e) => {
    const { gameId, token } = props
    const iframeWindow = e.target.contentWindow
    iframeWindow.postMessage({ gameId, token, api }, '*')
  }

  const isValidUrl = (url) => {
    const regexPattern = new RegExp('^(http|https)://', 'i')
    return regexPattern.test(url)
  }

  const { src } = props

  if (!isValidUrl(src)) {
    return (
      <Typography variant="body1" color="error" align="center">
        Invalid URL
      </Typography>
    )
  }

  delete props.token
  delete props.gameId
  return <iframe title="game-iframe" onLoad={handleLoad} {...props} />
}

export default GameFrame
