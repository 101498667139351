import Checkbox from '@material-ui/core/Checkbox'
import TablePagination from '@material-ui/core/TablePagination'
import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { DeleteGame } from './Delete'

const pageTitle = 'Games'
const columns = [
  { title: 'Title', field: 'title' },
  { title: 'Ref', field: 'referenceSource' },
  { title: 'Free', field: 'isFree', type: 'boolean' },
  {
    title: 'Published',
    field: 'state',
    type: 'boolean',
    render: (rowData) => (
      <Checkbox checked={rowData.state === 'published'} disabled />
    ),
  },
]
const options = {
  sorting: true,
  columnsButton: true,
  exportButton: true,
  // filtering: true,
  emptyRowsWhenPaging: false,
  pageSize: 10,
  actionsColumnIndex: -1,
}

// The pagination component is not right with rtl
const components = {
  Pagination: (props) => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

const GameTable = (props) => {
  const [deleteGame, setDeleGame] = useState<any>(null)

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit game',
      onClick: (e, row) => {
        handleEdit(e, row)
      },
    },
    {
      icon: 'delete',
      tooltip: 'Delete game',
      onClick: (e, row) => {
        handleDelete(e, row)
      },
    },
  ]

  const handleEdit = (e, rowData) => {
    const { history } = props
    history.push(`/games/${rowData._id}`)
  }

  const handleDelete = (e, rowData) => {
    openDelete(rowData)
  }

  const handleAfterDelete = () => {
    const { onDelete } = props
    closeDelete()
    if (onDelete) onDelete()
  }

  const openDelete = (rowData) => {
    setDeleGame(rowData)
  }

  const closeDelete = () => {
    setDeleGame(null)
  }

  const { data } = props
  return (
    <React.Fragment>
      <MaterialTable
        // @ts-expect-error legacy
        columns={columns}
        data={data}
        title={pageTitle}
        options={options}
        components={components}
        actions={actions}
      />
      <DeleteGame
        game={deleteGame}
        forceOpen={!!deleteGame}
        onClose={closeDelete}
        onAfterDelete={handleAfterDelete}
      />
    </React.Fragment>
  )
}

export default withRouter(GameTable)
