// eslint-disable-next-line @typescript-eslint/no-unused-vars
const commonStyle: any = (theme) => ({
  root: {},
})

export const EpisodeAddStyles: any = (theme: any) => ({
  ...commonStyle(theme),
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },
})

export const EpisodeDetailStyles: any = (theme) => ({
  ...commonStyle(theme),
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
  fab: {
    top: 'auto',
    right: '3rem',
    bottom: '2rem',
    left: 'auto',
    position: 'fixed',
  },
})
