import { Grid, Paper, withStyles, Button } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Helper from '../../utils/Helper'
import { arrayDifferent, move } from '../../utils/array'
// import { Container, Draggable } from 'react-smooth-dnd'
import { OnboardingsStyle as styles } from './OnboardingStyles'

const { Container, Draggable }: any = require('react-smooth-dnd')

const Onboardings = (props: any) => {
  const { history, classes } = props
  const [onboardings, setOnboardings]: any = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const data: any = await Helper.ApiRequest('/onboarding')
    const onboardings = data.sort((a, b) => a.orderNo - b.orderNo)
    setOnboardings(onboardings)
  }

  const updateData = async (onboarding) => {
    const body = JSON.stringify(onboarding)
    const path = `/onboarding/${onboarding._id}`
    const method = 'PUT'
    await Helper.ApiRequest(path, { method, body })
    fetchData()
  }

  const updateManyDate = async (onboardings) => {
    const body = JSON.stringify(onboardings)
    const path = '/onboarding'
    const method = 'PUT'
    await Helper.ApiRequest(path, { method, body })
    fetchData()
  }

  const deleteData = async (onboarding) => {
    const path = `/onboarding/${onboarding._id}`
    const method = 'DELETE'
    await Helper.ApiRequest(path, { method })
    fetchData()
  }

  const handleSwitch = (e, onboarding) => {
    e.preventDefault()
    onboarding.status = e.target.checked
    updateData(onboarding)
  }

  const handleEdit = (onboarding) => {
    history.push(`/onboardings/edit/${onboarding._id}`)
  }

  const handleAdd = () => {
    history.push('/onboardings/add')
  }

  const handleReorder = ({ removedIndex, addedIndex }) => {
    const cpOnboardings = move(removedIndex, addedIndex, ...onboardings).map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ orderNo, ...rest }, i) => ({ ...rest, orderNo: i }),
    )

    const unique = arrayDifferent(
      cpOnboardings,
      onboardings,
      (a) => (b) => a._id === b._id && a.orderNo === b.orderNo,
    )

    setOnboardings(cpOnboardings)
    updateManyDate(unique)
  }

  return (
    <React.Fragment>
      <Typography component="a" variant="h4" align="center">
        Manage Onboarding Pages
      </Typography>
      <Paper className={classes.paper}>
        <Grid
          className={classes.container}
          container
          spacing={8}
          direction="column"
          alignItems="center"
        >
          <div className={classes.container}>
            <Container onDrop={handleReorder}>
              {onboardings.map((onboarding) => (
                <Draggable key={onboarding._id}>
                  <Grid
                    key={onboarding._id}
                    item
                    container
                    alignItems="center"
                    justify="space-around"
                  >
                    <Grid item xs={5}>
                      {onboarding.title}
                    </Grid>
                    <Grid item>
                      <Switch
                        checked={onboarding.status}
                        onChange={(e) => {
                          handleSwitch(e, onboarding)
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        component="span"
                        className={classes.button}
                        color="default"
                        onClick={() => {
                          handleEdit(onboarding)
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        component="span"
                        className={classes.button}
                        color="primary"
                        onClick={async () => {
                          await deleteData(onboarding)
                        }}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Draggable>
              ))}
            </Container>
          </div>
          <Grid item>
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              color="primary"
              onClick={handleAdd}
            >
              Add Page
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  )
}

export default withRouter(withStyles(styles)(Onboardings))
